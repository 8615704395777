<template>
  <main id="content" role="main">
    <section class="report_group">
      <section class="report_box">
        <div class="contest-detail">
          <div class="title">{{ detail.title }}</div>
          <ul class="info">
            <!-- TODO: 상시모집일 경우 모집기간 없음 -->
            <li>
              <span class="subtitle">{{ $t('content.accelerating.Common.txt20') }}</span>
              <span v-if="detail.isAlwaysRecruitmen">{{ $t('content.accelerating.Common.txt29') }}</span>
              <span v-else>{{ detail.startDate }} - {{ detail.endDate }}</span>
            </li>
            <li>
              <span class="subtitle">{{ $t('content.accelerating.Common.txt21') }}</span>
              <strong>{{ detail.announcementOfPass }}</strong></li>
          </ul>
          <div class="floating" v-if="detail.state === 1">
            <!-- <a :href="`/programs/application/${detail.id}/apply/form`" class="link">
              <strong>신청하기</strong>Click
            </a> -->
            <a class="link" @click.prevent="noDisplay">
              <strong>{{ $t('content.accelerating.Common.txt30') }}</strong>Click
            </a>
          </div>
          <div class="intro">
            <img v-for="img in detail.imageBannerList" :src="`${ img.url || example }`" />
          </div>
        </div>
      </section>
    </section>
  </main>
</template>

<script>

import { mixin as mixinHelperUtils } from '@/common/helperUtils';
import { APIs } from '@/common/portalApi';
import PopupApply from '@/components/popup/PopupApplyDetail.vue';

export default {
  name: 'ContestDetail',
  components: {
  },
  metaInfo() {
    return {
      meta: this.$route.meta.metaInfo
    };
  },
  data() {
    return {
      example : 'https://cdn.imweb.me/thumbnail/20230405/41c789bb76fd0.png',
      detail: {
        title: null,
        startDate: null,
        endDate: null,
        announcementOfPass: null,
      }
    };
  },
  mixins: [
    mixinHelperUtils,
  ],
  created() {
    if (this.$route.params.id) {
      this.getDetail();
    }
  },
  methods: {
    noDisplay() {
      this.showAlert('공모 지원은 PC 환경에서만 지원 가능합니다. PC 화면에서 진행해 주시기 바랍니다.');
    },
    getDetail() {
      this.portalApiClient({
        url: `${APIs.CONTEST_API}/${this.$route.params.id}`,
        method: 'get',
        data: {}
      }).then(({ data }) => {
        if (data.result) {
          this.detail = data.data;
          if (this.detail && this.detail.imagePopupList && this.detail.imagePopupList.length > 0) {
            this.showPopup(PopupApply, 'pop-list', {popupList: this.detail.imagePopupList});
          }
        } else {
          this.showAlert(data.message);
        }
      });
    },
  }
};
</script>
<style>
</style>
